import { getCookie, setCookie } from '/lib/cookie'

const yzModalCloseBtn = document.querySelector('.yz-modal-closebtn')
const yzModalBtnClick = document.querySelector('.yz-modal-cta-btn')
const yzModalCloser = document.querySelector('.yz-modal-terminator')

const newVisitorPopUpStart = async () => {
  setTimeout(() => {
    document.querySelector('.yz-modal').style.visibility = 'visible'
  }, 5000)
}

function isHomePage() {
  if (window.location.pathname === '/') {
    return true
  }
  return false
}

function isFirstTimeVisitor() {
  const firstTimeVisitDate = getCookie('yzftv')
    ? new Date(getCookie('yzftv'))
    : new Date()
  const today = new Date()
  return (
    firstTimeVisitDate &&
    firstTimeVisitDate.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0)
  )
}

if (!getCookie('yzmc') && isFirstTimeVisitor() && isHomePage()) {
  newVisitorPopUpStart()

  yzModalCloseBtn.addEventListener('click', (e) => {
    e.preventDefault()
    document.querySelector('.yz-modal').remove()
    setCookie('yzmc', 'true', 365)
  })
  yzModalBtnClick.addEventListener('click', (e) => {
    setCookie('yzmc', 'true', 365)
  })
  yzModalCloser.addEventListener('click', (e) => {
    e.preventDefault()
    document.querySelector('.yz-modal').remove()
    setCookie('yzmc', 'true', 365)
  })
}
